// import React from 'react';
// import '../utils/Style/Components/Footer.scss';
//
// const Footer = () => {
//     return (
//         <footer className="footer">
//             <div className="container">
//                 <div className="row">
//                     <div className="col-md-6">
//                         <p>&copy; 2023 Your Company. All rights reserved.</p>
//                     </div>
//                     <div className="col-md-6">
//                         <ul className="footer-links">
//                             <li><a href="/">Home</a></li>
//                             <li><a href="/about">About</a></li>
//                             <li><a href="/services">Services</a></li>
//                             <li><a href="/contact">Contact</a></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//         </footer>
//     );
// };
//
// export default Footer;


import React from 'react';
import '../utils/Style/Components/Footer.scss';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <footer className="footer" style={{ backgroundColor: '#333' }}>
            <div className="container">
                <div className="row">
                    <div className="">
                        <p style={{ color: '#fff' }}>&copy; 2023 Fekra Bteghnike. All rights reserved.</p>
                    </div>
                    <div className="col-md-6">
                        <ul className="footer-links">
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/services">Services</a></li>
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                        {/* <div className="social-icons">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FaFacebook className="icon" />
                            </a>
                            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                                <FaTwitter className="icon" />
                            </a>
                            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                                <FaInstagram className="icon" />
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
