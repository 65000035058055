import React from 'react';
import "../../utils/Style/homepage/review-section.scss";

const ReviewSection = () => {
    const title={
        english: "Success Stories",
        arabicReviews: "قصص النجاح"
    }

    const englishReviews = [
        {
          text: "My whole life has changed and my husband and I have learned a lot from you. He used to be tough in parenting and would hit the children, but now he eagerly awaits every consultation. He has really changed... And the most memorable sentence from the parenting fundamentals workshop that has stuck in my mind is that children's annoying behaviors are not behaviors, but needs."
        },
        {
            text: "You are our guide... I have many problems, but despite that, I am the happiest person with my daughter and what I have achieved in less than a year, only six months by following your consultations and anger management workshop. I am very grateful to you and happy that I found a treatment like you, knowing that I have followed many specialists but without any results. But you are special and you give us from your heart. The word 'thank you' is not enough."
        }
    ];

    const arabicReviews = [
        {
            text: "تغيرت حياتي كلها وتعلمت الكثير منك ومن زوجي. كان يكون صارمًا في التربية وكان يضرب الأطفال، ولكن اليوم ينتظر كل استشارة بشغف. لقد تغير حقًا... وأكثر جملة لا تنسى من ورشة أسس التربية التي ظلت في ذهني هي أن سلوك الأطفال المزعج ليس سلوكًا، بل احتياجات."
        },
        {
            text: "أنت دليلنا... لدي العديد من المشاكل، ولكن على الرغم من ذلك، أنا أسعد شخص مع ابنتي وما حققته في أقل من عام، فقط ستة أشهر من خلال اتباع استشاراتك وورشة إدارة الغضب. أنا ممتن جدًا لك وسعيد بأنني وجدت علاجًا مثلك، علمًا بأنني اتبعت العديد من الأخصائيين ولكن بدون أي نتائج. لكنك خاص وتعطينا من قلبك. الكلمة 'شكرًا' لا تكفي."
        }
    ];

    return (
        <div className="review-section">
            <h2>{title.arabicReviews}</h2>

            {arabicReviews.map((review, index) => (
                <div className="review-container" key={index}>
                    <div className="review-message">
                        <p>
                            {review.text}
                        </p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ReviewSection;
