import "../../utils/Style/homepage/hero.scss";

const Hero = () => {
    const englishHero = {
        title: "Fekra Bteghniki",
        subtitle: "Empowering Mothers for a Better Tomorrow",
        description: "Discover scientifically-backed methods and educational tips to enhance your motherhood journey and raise a happy, successful generation.",
        actionButton: "Get Started Now",
    }

    const arabicHero = {
        title: "فكرة بتغنيكي",
        subtitle: "تمكين الأمهات لغدٍ أفضل",
        description: "اكتشفي الطرق المدعمة علميًا والنصائح التعليمية لتعزيز رحلتك في الأمومة وتربية جيل سعيد وناجح",
        actionButton: "ابدأي الآن",
    }

    const scrollToForm = () => {
        const form = document.querySelector('.social-media');
        form && form.scrollIntoView({ behavior: 'smooth' });
    }
 
    return (
        <div className="hero">
            <div className="hero-content">
                <h1>{arabicHero.title}</h1>
                <h2>{arabicHero.subtitle}</h2>
                <p>{arabicHero.description}</p>
                <button onClick={scrollToForm}>{arabicHero.actionButton}</button>
            </div>
            {/* <div class="img-container">
                <img src="/images/hero.webp" alt="Mother and child" />
            </div> */}
        </div>
    );
};

export default Hero;