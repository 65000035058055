import "../utils/Style/Components/Header.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPersonBreastfeeding} from "@fortawesome/free-solid-svg-icons";

function Header() {
    return (
        <div className="header">
            <div className="header-content">
                {/* <div className="icon">
                    <FontAwesomeIcon icon={faPersonBreastfeeding}/>
                </div> */}
                <div className="title">فكرة بتغنيكي</div>
                <div className="slogan">BY MADONA</div>
            </div>
        </div>
    );
}

export default Header;