import './App.css';
import Homepage from './pages/homepage';
import 'bootstrap/dist/css/bootstrap.min.css';
import Shop from './pages/shop';
import {BrowserRouter, Route, Routes} from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Homepage />} />
        <Route path='/shop' element={<Shop />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
