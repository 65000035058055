import React from 'react';
import "../../utils/Style/homepage/our-services.scss";
import Service from "./Service";
import {faChalkboardUser, faHandHoldingHeart, faPeopleGroup} from "@fortawesome/free-solid-svg-icons";

function OurServices() {

    const englishServices = [
        {
            text: "Educational and family consultations",
            description: "in-person and remotely from all Arab countries and European countries",
            icon: faPeopleGroup
        },
        {
            text: "Workshops",
            description: "Foundations of Education Workshop 1 and 2, Anger and Emotions Management Workshop",
            icon: faChalkboardUser
        },
        {
            text: "Therapy sessions for children.",
            description: "Professional therapy sessions for children, in-person and remote options available.",
            icon: faHandHoldingHeart
        }
    ];

    const arabicServices = [
        {
            text: "الاستشارات التعليمية والأسرية",
            description: "شخصياً وعن بعد من جميع الدول العربية والدول الأوروبية",
            icon: faPeopleGroup
        },
        {
            text: "ورش العمل",
            description: "ورش عمل أسس التربية 1 و 2، ورشة إدارة الغضب والعواطف",
            icon: faChalkboardUser
        },
        {
            text: "جلسات علاجية للأطفال.",
            description: "جلسات علاجية محترفة للأطفال، خيارات شخصية وعن بعد متاحة.",
            icon: faHandHoldingHeart
        },
        {
            text: "أنشطة موسمية في مركزنا",
            description: "أنشطة ممتعة وتعليمية تقام خلال الأحداث الموسمية، توفر بيئة حاضنة للأطفال للتعلم والنمو واللعب.",
            icon: faChalkboardUser
        },
        {
            text: "توفير ألعاب لأغراض تعليمية.",
            description: "توفير ألعاب تعليمية للأطفال، تعزز التعلم وتطوير المهارات الحركية والذهنية.",
            icon: faChalkboardUser
        }

    ];

    return (
        <div className="services row">
            {/* <Service
                text="Educational and family consultations"
                description="in-person and remotely from all Arab countries and European countries"
                icon={faPeopleGroup}
            /> */}

            {arabicServices.map((service, index) => (
                <Service
                    key={index}
                    text={service.text}
                    description={service.description}
                    icon={service.icon}
                />
            ))}
            
        </div>
    );
}

export default OurServices;
