import React from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import "../utils/Style/Shop/shop.scss";

const phoneNumber = '+9613658590';

const WhatsAppShareButton = ({ selectedItem }) => {
    // const message = `أود معرفة المزيد عن هذا المنتج.\nتفاصيل المنتج: ${selectedItem.name}\n${selectedItem.slug}`;
    const message = `\u202Bأود معرفة المزيد عن هذا المنتج.\nتفاصيل المنتج: \u202A${selectedItem.name}\u202C`;

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(`${message}`)}`;
  
  
    return (
        <a href={whatsappUrl} className="buy-button" target="_blank" rel="noopener noreferrer">
            اشتر الآن
        </a>
    );
  };

function Shop() {
    

    const items = [
        {
            imgUrl: '/Images/shop/image1.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item1.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Magnetic Board',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item2.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Magnetic Board',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item3.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Magic Book',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item4.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Projector',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item5.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Magnetic Sticks',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item6.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Sensory Book',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item7.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Tangram',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item8.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Magnetic Puzzle Book',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item9.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Flash cards',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item10.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج',
            name: 'Silicone Lego',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item11.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Color Classification',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item12.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Tubes',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item13.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Watwe magic book',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item14.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Paiting bag',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item15.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'eggs shapes',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item16.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Wood Puzzle',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item17.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Plastic tour',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item18.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Baby water mat',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item19.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'flying doll plane',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item20.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'train shapes',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item22.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Shapes wood',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item23.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Blocks shapes 4 in 1',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item24.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Blocks shapes 5 in 1',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item25.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Music baby mat',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item26.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Geometric columns',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item27.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Baby park with balls',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item28.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Baby sensory balls/books',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item29.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'dauphin dart',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item30.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Electronic book',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item31.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'watch car',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item32.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Paiting roll',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item33.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Throwing ball',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item34.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Jinga',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item35.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Speed push',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item36.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Paiting table',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item37.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Cotton sand',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item38.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: '3 in 1 board',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item39.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Car racing red',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item40.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'car racing blue',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item41.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Puzzle shapes',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item42.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'Owl shapes',
            show: true,
        },
        {
            imgUrl: '/Images/shop/item43.jpeg',
            itemUrl: 'https://wa.me/whatsappphonenumber?text=أود%20شراء%20هذا%20المنتج', 
            name: 'pipe ball',
            show: true,
        },
    ];

    return (
        <div className="homepage ar">
            <Header />

            <div className="shop-container">
                <h2 className="shop-title">تسوق منتجاتنا</h2>
                
                <div className="items-grid">
                    {items.map((item, index) => {
                        if(item.show) {
                            return (
                                <div key={index} className="item-card">
                                    <img src={item.imgUrl} alt={`item-${index}`} className="item-image" />
                                    <p className="item-description">{item.description}</p>
                                    {item.name && <WhatsAppShareButton selectedItem={item} />}
                                </div>
                            )
                        }
                    })}
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Shop;