import React, { useState } from 'react';
import "../../utils/Style/homepage/social-media.scss";

const SocialMedia = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [inClinic, setInClinic] = useState(false);
    const [errors, setErrors] = useState({});

    const englishText = {
        title: 'Schedule Your Appointment with Us',
        whatsapp: 'Chat with Us on WhatsApp',
        title2: 'Connect with Us on Social Media',
        tiktok: 'Follow Us on TikTok',
        facebook: 'Like Us on Facebook',
        instagram: 'Follow Us on Instagram',
        twitter: 'Follow Us on Twitter'
    };

    const arabicText = {
        title: 'حدد موعدك معنا',
        whatsapp: 'تحدث معنا عبر واتساب',
        title2: 'تواصل معنا عبر وسائل التواصل الاجتماعي',
        tiktok: 'تابعنا على تيك توك',
        facebook: 'أعجب بنا على فيسبوك',
        instagram: 'تابعنا على إنستغرام',
        twitter: 'تابعنا على تويتر',
        shopTitle: 'استكشف متجر ألعابنا' 
    };

    return (
        <div className="social-media">
            <h3 className="title">{arabicText.title}</h3>
            
            {/* WhatsApp Button */}
            <a href="https://wa.me/+9613658590" className="social-link">
                <img src='/Images/WhatsApp.webp' alt='whatsapp-icon' />
                {arabicText.whatsapp}
            </a>

            <h3 className="title">{arabicText.title2}</h3>
            
            {/* Social Media Links */}
            <div className="social-media-links">
                <a href="https://www.tiktok.com/@madonamerheb" className="social-link">
                    <img src='/Images/tiktok.webp' alt='tiktok-icon' />
                    {arabicText.tiktok}
                </a>

                <a href="https://www.facebook.com/profile.php?id=100059053279892&mibextid=LQQJ4d" className="social-link">
                    <img src='/Images/facebook.png' alt='facebook-icon' />
                    {arabicText.facebook}
                </a>
            </div>

            {/* Shop Link */}
            <h3 className="title">{arabicText.shopTitle}</h3>
            <a href="/shop" className="social-link shop-link">
                {arabicText.shopTitle}
            </a>
        </div>
    );
};

export default SocialMedia;