import React from 'react';
import "../../utils/Style/homepage/about-us.scss";
import { text } from '@fortawesome/fontawesome-svg-core';

const AboutUs = (props) => {

    const englishAbout = {
        weAre: {
            title: "Who we are",
            text: "Fekra Btghaniki is a valuable resource for every mother who seeks to improve her journey in motherhood. This project offers educational tips and proven scientific methods that make your life easier and happier. We share our experiences and address the challenges you face with your children from birth to the age of six, which is the most important stage in shaping their personalities. Together, we can build a generation that is aware, happy, and successful."
        },
        experience: {
            title: "Our experience",
            text: "Holder of a certificate in specialized developmental education dealing with autism, cerebral palsy, and learning difficulties. A researcher and specialist in the fields of education, psychology, and the Montessori method. A certified trainer and educational and family consultant. Founder of the project 'Fekra Btghaniki' on all social media platforms."
        },
        mission: {
            title: "Our mission",
            text: [
                "Empower mothers with knowledge and confidence.",
                "Provide reliable and scientifically-backed parenting methods.",
                "Foster a supportive community for mothers."
            ]
        }
    };

    const arabicAbout = {
        weAre: {
            title: "من نحن",
            text: "فكرة بتغنيكي هو مورد قيم لكل أم تسعى لتحسين رحلتها في الأمومة. يقدم هذا المشروع نصائح تعليمية وطرق علمية مثبتة تجعل حياتك أسهل وأكثر سعادة. نشاركك تجاربنا ونتناول التحديات التي تواجهينها مع أطفالك من الولادة حتى سن السادسة، وهي المرحلة الأهم في تشكيل شخصياتهم. معًا، يمكننا بناء جيل واعٍ، سعيد، وناجح."
        },
        experience: {
            title: "تجربتنا",
            text: "حاصلة على شهادة في التربية التقويمية  التي تعنى في التعامل مع حالات التوحد، الشلل الدماغي، وصعوبات التعلم. باحثة ومتخصصة في مجالات التعليم وعلم النفس. مدربة معتمدة ومستشارة تربوية وأسرية. مؤسسة مشروع 'فكرة بتغنيكي' على جميع منصات التواصل الاجتماعي."
        },
        mission: {
            title: "رسالتنا",
            text: [
                "تمكين الأمهات بالمعرفة والثقة.",
                "توفير طرق تربية موثوقة ومدعمة علميًا.",
                "تعزيز مجتمع داعم للأمهات."
            ]
        }
    };

    return (
    <div className="container-fluid about-us">
        <div className="row">
            <div className="about-img col-md-6 col-sm-12"></div>
            <div className="about-text col-md-6 col-sm-12">
                <h2>{arabicAbout.weAre.title}</h2>
                {arabicAbout.weAre.text}
            </div>
        </div>
        <div className="row">
            <div className="about-img-2 col-md-6 col-sm-12 order-md-2"></div>
            <div className="about-text col-md-6 col-sm-12 order-md-1">
                <h2>{arabicAbout.experience.title}</h2>
                {arabicAbout.experience.text}
            </div>
        </div>
        <div className="row">
            <div className="about-text col-md-12">
                <h2>{arabicAbout.mission.title}</h2>
                <ul>
                    {arabicAbout.mission.text.map((mission, index) => (
                        <li key={index}>{mission}</li>
                    ))}
                </ul>
            </div>
        </div>
    </div>
)};

export default AboutUs;