import Header from '../components/Header';
import AboutUs from "../components/homepage/AboutUs";
import OurServices from "../components/homepage/OurServices";
import AppointmentForm from "../components/homepage/AppointmentForm";
import ReviewSection from "../components/homepage/ReviewSection";
import Footer from "../components/Footer";
import Hero from '../components/homepage/Hero';
import SocialMedia from '../components/homepage/SocialMedia';

function Homepage() {
    return (
        <div className="homepage ar">
            <Header/>
            <Hero />
            <AboutUs />
            <OurServices />
            <SocialMedia />
            <ReviewSection />
            <Footer />
        </div>
    );
}

export default Homepage;
