import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Service = ({icon, text, description}) => (
    <div className="service ar col-md-4 col-12">
        <div className="icon">
            <FontAwesomeIcon icon={icon}/>
            {/*<img src={icon} alt=""/>*/}
        </div>
        <div className="message">
            <div className="title">{text}</div>
            <div className="description">{description}</div>
        </div>
    </div>
);

export default Service;